import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface SoilPitObj {
  pitName: string;
  pitLength: number;
  pitWidth: number;
  pitHeight: number;
  pitPhoto: string;
}

type EditSoilPitModalProps = {
  soilPitId: string;
  pitName: string;
  pitLength: number;
  pitWidth: number;
  pitHeight: number;
  pitPhoto: string;
};

const EditSoilPitModal: React.FC<EditSoilPitModalProps> = ({
  soilPitId,
  pitName,
  pitLength,
  pitWidth,
  pitHeight,
  pitPhoto,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: SoilPitObj = {
    pitName,
    pitLength,
    pitWidth,
    pitHeight,
    pitPhoto,
  };

  const validationSchema = Yup.object({
    pitName: Yup.string().required("Pit Name is Required"),
    pitLength: Yup.number().required("Pit Length is Required"),
    pitWidth: Yup.number().required("Pit Width is Required"),
    pitHeight: Yup.number().required("Pit Height is Required"),
    pitPhoto: Yup.string().required("Pit Photo is Required"),
  });

  const handleSubmit = useCallback(
    async (soilPit: SoilPitObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.patch(
          `/soil_pit/update/${soilPitId}`,
          {
            pit_name: soilPit.pitName,
            pit_length: soilPit.pitLength,
            pit_width: soilPit.pitWidth,
            pit_height: soilPit.pitHeight,
            pit_photo: soilPit.pitPhoto,
          },
          { headers: { ContentType: "application/json" } }
        );
        

        queryClient.invalidateQueries(["getAllSoilPitDetails"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Soil pit updated successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [dispatch, queryClient, soilPitId]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
          height: "auto",
        }}
      >
        <div className="flex item-center gap-x-6">
          <Input label="Pit Name" id="pitName" name="pitName" type="text" />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Pit Length"
            id="pitLength"
            name="pitLength"
            type="number"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Pit Width"
            id="pitWidth"
            name="pitWidth"
            type="number"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Pit Height"
            id="pitHeight"
            name="pitHeight"
            type="number"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input label="Pit Photo" id="pitPhoto" name="pitPhoto" type="text" />
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default EditSoilPitModal;
