import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ASSIGN_PRODUCT_TO_FPO_MODAL,
  FORMULATION_FORM_MODAL
} from "../../types/constants";

interface UserObj {
  serialNumber: number;
  sp_id: string;
  sequestration_ids: string;
  product_name: string;
  material_id: number;
  material_qty: number;
  total_qty: number;
  created_at: string;
  updated_at: string;
}

const AllProductFormulation = () => {
  const queryClient = useQueryClient();


  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },

    {
      Header: "Product",
      accessor: "product_name",
    },

    {
      Header: "Batch ID",
      accessor: "sp_id",
    },
    // {
    //   Header: "Material Quantity",
    //   accessor: (row: { material_qty: number }) => `${row.material_qty} kg`,
    // },
    // {
    //   Header: "Total Batch Quantity",
    //   accessor: (row: { total_batch_qty: number }) => `${row.total_batch_qty} kg`,
    // },
    {
      Header: "Total Quantity",
      accessor: (row: { total_qty: number }) => `${row.total_qty} kg`,
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ value }: any) => {
        const date = new Date(value);

        
        const options: Intl.DateTimeFormatOptions = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
          timeZone: "Asia/Kolkata",
        };

        return date.toLocaleString("en-IN", options);
      },
    },

    {
      Header: "Assign",
      accessor: "assign",
      Cell: ({ value, row }: any) => {
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ASSIGN_PRODUCT_TO_FPO_MODAL,
                  modalTitle: "Assign To FPO",
                  modalProps: {
                    sp_id: row.original.sp_id,
                    qty: row.original.total_qty,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            Assign to FPO
          </button>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getProductFormulation = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/suncarbon_product/all`);
    
    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(
    ["getProductFormulation", pageIndex, cPageSize, cSortBy, desc, q],
    getProductFormulation
  );

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => {}}
          />
        </div>

        <div className="right">
          <div className="flex flex-row gap-4">
            <button
              className="btn-primary flex items-center"
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: FORMULATION_FORM_MODAL,
                    modalTitle: "Formulation Form",
                  })
                );
              }}
            >
              <span className="mr-1">Formulate</span> <MdAdd size={17} />
            </button>
          </div>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default AllProductFormulation;
