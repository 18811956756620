import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { EDIT_PRODUCT_MODAL } from "../../types/constants";

interface UserObj {
  id: string;
  name: string;
  createdAt: string;
}

const AllProducts = () => {
  const queryClient = useQueryClient();

  const handleApproveProduct = (id: string) => {
    axiosInstance.get(`/admin/products/${id}/approve`).then((res: any) => {
      
      toast.success("Product Approved Successfully!");
      queryClient.invalidateQueries("getProducts");
    });
  };

  const handleSoftDeleteProduct = (id: string) => {
    axiosInstance.delete(`/products/${id}`).then((res: any) => {
      
      toast.success("Product Deleted Successfully!");
      queryClient.invalidateQueries("getProducts");
    });
  };

  const ALL_USERS_COLUMNS = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Price",
      accessor: "actualPrice",
      Cell: ({ value, row }: any) => {
        console.table(row.original);
        return (
          <>
            {value === row.original.discountedPrice ? (
              <p>{row.original.discountedPrice}</p>
            ) : (
              <p>
                <del>{value}</del> {row.original.discountedPrice}
              </p>
            )}
          </>
        );
      },
    },
    {
      Header: "Stock ( KG )",
      accessor: "stockInKG",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: ({ value }: any) => {
        return new Date(value).toLocaleString();
      },
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Verified?",
      accessor: "verified",
      Cell: ({ value, row }: any) => {
        return value ? (
          "Yes"
        ) : (
          <button
            onClick={() => handleApproveProduct(row.original.productId)}
            className="bg-green-600 text-white px-2 py-3"
          >
            Approve Product
          </button>
        );
      },
    },

    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ value, row }: any) => {
        console.table(row.original);
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_PRODUCT_MODAL,
                  modalTitle: "Edit a Product",
                  modalProps: {
                    productId: row?.original.productId,
                    name: row?.original.name,
                    actualPrice: row?.original.actualPrice,
                    discountedPrice: row?.original.discountedPrice,
                    stock: row?.original.stockInKG,
                    addressId: row?.original.addressId,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            Edit Product
          </button>
        );
      },
    },

    {
      Header: "Delete",
      accessor: "updatedAt",
      Cell: ({ value, row }: any) => {
        return (
          <button
            onClick={() => handleSoftDeleteProduct(row.original.id)}
            className="bg-green-600 text-white px-2 py-3"
          >
            Delete Product
          </button>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getProducts = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/products`);

    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(["getProducts", pageIndex, cPageSize, cSortBy, desc, q], getProducts);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => {}}
          />
        </div>

      </header>

      {component !== null && component}
    </div>
  );
};

export default AllProducts;
