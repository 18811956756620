import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface ArtisanObj {
  artisanId: string;
  name: string;
  address: string;
  companyEmail: string;
  artisanKycDocUrl: string;
  companyMobile: number;
  siteImageUrl: string;
}

type EditProductModalProps = {
  artisanId: string;
  name: string;
  address: string;
  companyEmail: string;
  artisanKycDocUrl: string;
  companyMobile: number;
  siteImageUrl: string;
  addressId: string;
};

const EditProductModal: React.FC<EditProductModalProps> = ({
  artisanId,
  name,
  address,
  companyEmail,
  artisanKycDocUrl,
  companyMobile,
  siteImageUrl,
  addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: ArtisanObj = {
    name: name,
    address: address,
    companyEmail: companyEmail,
    artisanKycDocUrl: artisanKycDocUrl,
    artisanId: artisanId,
    companyMobile: companyMobile,
    siteImageUrl: siteImageUrl,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Category Title is Required"),
    address: Yup.string(),
    companyEmail: Yup.string(),
    artisanKycDocUrl: Yup.string(),
    siteImageUrl: Yup.string(),
  });

  const handleSubmit = useCallback(
    async (product: ArtisanObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.patch(
          `/artisans/${artisanId}`,
          {
            name: product.name,
            artisanId: product.artisanId,
            address: product.address,
            companyEmail: product.companyEmail,
            companyMobile: product.companyMobile,
            siteImageUrl: product.siteImageUrl,
            artisanKycDocUrl: product.artisanKycDocUrl,
            addresses: [
              {
                addressId: addressId,
              },
            ],
          },
          { headers: { ContentType: "application/json" } }
        );

        queryClient.invalidateQueries(["getProducts"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Artisan Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [artisanId, addressId, dispatch, queryClient]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <div className="flex item-center gap-x-6">
          <Input label="Name" id="name" name="name" type="text" />
        </div>

        <div className="flex item-center gap-x-6">
          <Input label="Address" id="address" name="address" type="text" />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Company Email"
            id="companyEmail"
            name="companyEmail"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Company Mobile"
            id="companyMobile"
            name="companyMobile"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="KYC Image"
            id="artisanKycDocUrl"
            name="artisanKycDocUrl"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Photo Site"
            id="siteImageUrl"
            name="siteImageUrl"
            type="text"
          />
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default EditProductModal;
