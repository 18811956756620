import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import Select, { MultiValue } from "react-select";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { Option } from "../../types";
import { ERROR, SUCCESS } from "../../types/constants";
import Loader from "../Loader/Loader";

const AssignBiomassModal = ({
  artisan_id,
  assigned_biomass,
}: {
  artisan_id: string;
  assigned_biomass: Option[];
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bioMassOptions, setBiomassOptions] = useState<Option[] | null>(null);
  const [selectedBiomass, setSelectedBiomass] =
    useState<MultiValue<Option> | null>(null);

  const getAllBiomassComposition = async () => {
    try {
      const { data } = await axiosInstance.get(`/biomass_composition/all`);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: biomassData,
    status,
    isLoading,
  } = useQuery({
    queryKey: ["getAllBiomassComposition"],
    queryFn: getAllBiomassComposition,
  });

  const handleSubmit = async () => {
    if (!selectedBiomass || selectedBiomass.length === 0) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Please select at least one biomass",
        })
      );
      return;
    }

    setIsSubmitting(true);
    try {
      await Promise.all(
        selectedBiomass.map(async (e) => {
          await axiosInstance.patch("/biomass_composition/" + e.value, {
            artisan_id_assigned: artisan_id,
          });
        })
      ).then(() => {
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Biomass assigned successfully",
          })
        );
        queryClient.invalidateQueries("getAllBiomassComposition");
        dispatch(hideModal());
      });
    } catch (error: any) {
      setIsSubmitting(false);

      if (error.response) {
        const response = error.response;
        const { msg } = response.data;

        switch (response.status) {
          // bad request or invalid format or unauthorized
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (status === "success") {
      const options = biomassData.data.map((item: any) => {
        return {
          label: item.biomass_name,
          value: item.biomass_composition_id,
        };
      });
      setBiomassOptions(options);

    } else if (status === "error") {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "An error occurred while assigning biomass",
        })
      );
    }
  }, [status]);

  if (isLoading) return <Loader />;

  return (
    <div
      className="px-6 py-4 mt-2"
      style={{
        minWidth: "360px",
        maxWidth: "760px",
        display: "flex",
        flexDirection: "column",
        gap: 50,
      }}
    >
      <div className="flex flex-col gap-10">
        <p>Select Biomass</p>
        {bioMassOptions && (
          <Select
            options={bioMassOptions}
            isMulti
            onChange={(e) => {
              setSelectedBiomass(e);
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "darkgreen",
                outlineColor: "darkgreen",
                outline: "none",
              }),
            }}
            defaultValue={assigned_biomass}
          />
        )}
      </div>

      <div className="buttons flex items-center w-full justify-center my-4">
        <button
          onClick={() => {
            handleSubmit();
          }}
          className="btn-primary  w-full disabled:opacity-100 flex items-center justify-center cursor-pointer"
          disabled={isSubmitting}
        >
          Save
        </button>
      </div>
      
    </div>
  );
};

export default AssignBiomassModal;
