import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { Option } from "../../types";
import { ERROR } from "../../types/constants";

const ApproveArtisanModal = (props: any) => {

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getAllProjects = async () => {
    try {
      const { data } = await axiosInstance.get(`/project/all`);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, status } = useQuery({
    queryKey: ["getAllProjects"],
    queryFn: getAllProjects,
  });
  const [projects, setProjects] = useState<Option[] | null>(null);
  const [selectedProject, setSelectedProject] = useState<Option | null>(null);
  const handleSubmit = async () => {
    if (!selectedProject) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Please select project",
        })
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const { data } = await axiosInstance.patch(`/admin/artisan/status`, {
        artisan_ids: [props.data.artisan_id],
        status: "approved",
        project_id: selectedProject.value,
      });
      if (data) {
        toast.success("Artisan approved successfully");
        queryClient.invalidateQueries("getArtisans");
        dispatch(hideModal());
      }
    } catch (error: any) {
      setIsSubmitting(false);

      if (error.response) {
        const response = error.response;
        const { msg } = response.data;

        switch (response.status) {
          // bad request or invalid format or unauthorized
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (status === "success") {
      if (!data) return;
      const options = data?.data?.map((item: any) => {
        return {
          label: item.project_name,
          value: item.project_id,
        };
      });
      setProjects(options);
    } else if (status === "error") {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "An error occurred while fetching projects",
        })
      );
    }
  }, [status, props]);

  return (
    <div
      className="px-6 py-4 mt-2"
      style={{
        minWidth: "360px",
        maxWidth: "760px",
        display: "flex",
        flexDirection: "column",
        gap: 30,
      }}
    >
      <p>Name : {props.data.name}</p>
      <div className="flex flex-col gap-4">
        <p>Select Project:</p>
        {projects ? (
          <Select
            options={projects}
            // isMulti
            onChange={(e) => {
              setSelectedProject(e);
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "darkgreen",
                outlineColor: "darkgreen",
                outline: "none",
              }),
            }}
          />
        ) : (
          <span>No project to show</span>
        )}
      </div>

      <div className="buttons flex items-center w-full justify-center my-4">
        <button
          onClick={() => {
            handleSubmit();
          }}
          className="btn-primary  w-full disabled:opacity-100 flex items-center justify-center cursor-pointer"
          disabled={isSubmitting}
        >
          Approve Artisan
        </button>
      </div>
      
    </div>
  );
};

export default ApproveArtisanModal;
